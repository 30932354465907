.contenedorLogo{
    width: 10%;
    height: 100%;
    position: relative;
}
.logo{
    width: 30%;
    z-index: 1;
    display: flex;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}