.styleh3{
    margin: 0 auto;
    padding-top: 16%;
    padding-bottom: 5%;
    color: var(--color_text-secundary);
    font-family: var(--text_blue_font-family);
    font-size: var(--text_size_title);
    font-weight: bold;
    letter-spacing: -1px;
    @media (max-width: 560px) {
        font-size:large;
    }
}