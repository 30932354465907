.errorcontainer {
    background-color: #f7bfbe;
    border: 1px solid #ff0000;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    position: absolute;
    top: 370px;
    left: -75px;
    width: 140px;
    height: 45px;
}

.errormessage {
    color: #ff0000;
    text-align: center;
    line-height: 12px;
}