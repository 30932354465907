.estiloLaterales{
    width: 100%;
    background: var(--laterales_backgound-color);
    height: 100%;
    position: relative;
}
.contmenu{
    width: 80%;
    height: 96%;
    position: absolute;
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
}
.conticono{
    width: 20%;
    height: 100%;
    display: flex;
    margin-right: 0.5rem;
}
.conttexto{
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
}
.contsubtitulo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0 auto;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 300;
    p{
        margin: 0.3rem;
    }
    @media(max-width:940px){
        font-size: 10px;
    }
}
.bloques{
    width: 100%;
    height: 22%;
    /*border-bottom: 1px solid grey;*/
    margin-bottom: 0.5rem;
    @media(max-width:660px) {
        height: 17%;
    }
}
.linea{
    width: 100%;
    height: 15%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
}
hr{
    width: 100%;
}
.TopSeparation{
    margin-top: 8rem;
}