.HeaderStyle{
    width: 50%;
    height: 100%;
    background: var(--head-backgroud-color);
    display:flex;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
}
.LogoContainer, .spacer{
    width: 100%;
    height: 33%;
    position: relative;
}
.TitleContainer{
    width: 100%;
    height: 33%;
    position: relative;
    display: flex;
    align-items: center;
}
