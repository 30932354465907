.photostyle{
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 auto;
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}
