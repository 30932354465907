.part1{
    color: var(--color_text-grey);
    font-weight: bold;
    font-size: medium;
    display: flex;
    justify-content: center;
}
.part2{
    color: var(--color_text-secundary);
    font-weight: bold;
    font-size: medium;
    display: flex;
    justify-content: center;
}