.contCreateCompany{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
}
.styleForm{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //border: 1px solid blue;
}
.sectionStyles{
    display: flex;
    flex-direction: column;
    height: 85%;
    //border: 1px solid red;
}
.contForm{
    width: 100%;
    height: 100%;
    //border: 1px solid green;
}
.contLabels{
    width: 100%;
    display: flex;
}
.labelsStyle{
    font-size: var(--text_paragraph);
    color: var(--color_text-grey);
    line-height: 38px;
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items:flex-end;
}
.requiredStyle{
    font-size: var(--text_paragraph);
    color: red;
    line-height: 38px;
    width: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contInformation{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.labelLogo, .labelasterisco{
    height: 55px;
    display: flex;
    align-items: center;
}
.logostyle {
    width: 50px;
    height: 55px;
    position: relative;
}
.changeLogo{
    color: var(--head-backgroud-color);
    font-size: var(--text_size);
    font-weight: bold;
}
.redBorder{
    border: 1px solid #ff0000 !important;
}
.contButtonArrow{
    display: flex;
    width: 100%;
}
.contButtons{
    width: 50%;
    margin-top: 0;
    display: flex;
    justify-content: center;
    input{
        width: 50%;
    }
}
.title{
    align-items: flex-start;
}
.selectgroup{
    color: lightgray;
}
.conttitle{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    //border: 1px solid red;
}
.formLinelogo {
    width: 100%; /* Cada línea ocupa todo el ancho del contenedor */
    height: 20%;
  }
.formLine {
    width: 100%; /* Cada línea ocupa todo el ancho del contenedor */
    height: 15%;
    margin-bottom: 10px; /* Espacio entre las líneas */
    //border: 1px solid purple; /* Estilo de borde para cada línea (opcional) */
    padding: 5px; /* Espaciado interno para cada línea (opcional) */
  }
  .contbutton{
    width: 100%;
    height: 10%;
    //border: 1px solid pink;
    display: flex;
    align-items: center;
}
.contLabelslogo{
    width: 100%;
    height: 20%;
    display: flex;
    //border: 1px solid yellow;
    margin-bottom: 1%;
    font-size: var(--text_paragraph);
    color: var(--color_text-grey);
}
.contLabels{
    width: 100%;
    height: 40%;
    display: flex;
    //border: 1px solid yellow;
    margin-bottom: 1%;
    div{
        width: 50%;
        height: 100%;
        //border: 1px solid red;
        display: flex;
    }
    label{
        font-size: var(--text_paragraph);
        color: var(--color_text-grey);
    }
    span{
        font-size: var(--text_paragraph);
        color: red;
        padding-left: 2%;
    }
  }
  .contInputlogo{
    width: 100%;
    height: 60%;
    display: flex;
    border-radius: 5px;
    border: 1px solid lightgray;
    div{
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 2%;
    }
  }
  .contInput{
    width: 100%;
    height: 60%;
    display: flex;
    position: relative;
    //border: 1px solid green;
    div{
        width: 50%;
        height: 100%;
        //border: 1px solid red;
        display: flex;
    }
    input{
        width: 95%;
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    select{
        border-radius: 5px;
        border: 1px solid lightgrey;
        width: 98%;
        &.selected {
            border: 2px solid lightgrey; // Cambia el borde a rojo después de la selección
        }
        &:focus {
            border: 2px solid lightgrey; 
        }
    }
  }