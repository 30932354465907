.contText{
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
section p{
    width: 80%;
    margin: 0 auto;
    text-align: Center;
    font-size: var(--text_size);
    color: black;
    font-family: var(--text_font-family2);
}
.contButton{
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
}
.ContgenButton{
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    a{
        margin: 0 auto;
        width: 80%;
    }
}