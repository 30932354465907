.errorcontainer {
    background-color: #f7bfbe;
    border: 1px solid #ff0000;
    border-radius: 10px;
    padding: 10px;
    margin-top: 2px;
    position: absolute !important;
    width: 200px;
    height: 15px;
    z-index: 99;
    top: 100%;
}

.errormessage {
    color: #ff0000;
    text-align: center;
    line-height: 12px !important;
    font-size: 11px;
    width: 100% !important;
}