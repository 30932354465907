.contenedorGeneral{
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100vh;
    background: var(--background-color);
    position: relative;
}

.contenedorGeneralMobile{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}