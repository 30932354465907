.contUpdate{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
}
.centercontainer{
    width: 250px;
    height: 100%;
    margin: 0 auto;
}
.contLabels{
    width: 100%;
    display: flex;
}
.labelsStyle{
    font-size: var(--text_paragraph);
    color: var(--color_text-grey);
    line-height: 37px;
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items:flex-end;
}
.requiredStyle{
    font-size: var(--text_paragraph);
    color: red;
    line-height: 37px;
    width: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contInformation{
    width: 100%;
    height: 95%;
    /*line-height: 35px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4%;
    /*div{
        line-height: 20px;
        position: relative;
        margin-bottom: 2%;
        label{
            margin-left: 2%;
            font-size: var(--text_paragraph);
            color: var(--color_text-grey);
        }
        span{
            font-size: var(--text_paragraph);
            color: red;
            padding-left: 2%;
        }
        input{
            width: 93%;
            border: 1px solid lightgrey;
            border-radius: 15px;
            padding: 3%;
        }
        select{
            border-radius: 15px;
            border: 1px solid lightgrey;
            padding: 3%;
            width: 100%;
        }
    }*/
}
.styleForm{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.sectionStyles{
    display: flex;
    flex-direction: column;
    //border: 1px solid blue;
    height: 85%;
}
.contForm{
    width: 100%;
    height: 90%;
}
.contDocument{
    width: 100%;
    display: flex;
}
.sizeType{
    width: 25%;
    input{
        line-height: 27px;
        padding-left: 7%;
    }
}
.sizeLine{
    width: 10%;
    display: flex;
    justify-content: center;
}
.sizeNumber{
    width: 75%;
    input{
        line-height: 20px;
        padding-left: 7%;
    }
}
.colorfirstoption{
    color:lightgray !important;
}
.redBorder{
    border: 1px solid #ff0000 !important;
}
.changePhoto{
    color: var(--head-backgroud-color);
    font-size: var(--text_size);
    font-weight: bold;
    @media (max-width: 800px) {
        font-size: 9px;
    }
}
.contphoto{
    width: 100%;
    height: 15%;
    //border: 1px solid red;
    display: flex;
    align-items: center;
    margin-top: 2%;
}
.contphototext{
    width: 70%;
    height: 100%;
    margin: 0 auto;
    //border: 1px solid yellow;
    display: flex;
    align-items: center;
    justify-content: center;
}
.photo{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    //border: 1px solid green;
}
.textphoto{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    //border: 1px solid pink;
    justify-content: center;
}
.contbutton{
    width: 100%;
    height: 10%;
    //border: 1px solid pink;
    display: flex;
    align-items: center;
}
.formLine {
    width: 80%; /* Cada línea ocupa todo el ancho del contenedor */
    height: 9%;
    margin-bottom: 10px; /* Espacio entre las líneas */
    //border: 1px solid purple; /* Estilo de borde para cada línea (opcional) */
    padding: 5px; /* Espaciado interno para cada línea (opcional) */
  }
  .contLabels{
    width: 100%;
    height: 40%;
    display: flex;
    //border: 1px solid yellow;
    margin-bottom: 1%;
    div{
        width: 50%;
        height: 100%;
        //border: 1px solid red;
        display: flex;
    }
    label{
        font-size: var(--text_paragraph);
        color: var(--color_text-grey);
    }
    span{
        font-size: var(--text_paragraph);
        color: red;
        padding-left: 2%;
    }
  }
  .contInput{
    width: 100%;
    height: 60%;
    display: flex;
    position: relative;
    //border: 1px solid green;
    div{
        width: 50%;
        height: 100%;
        //border: 1px solid red;
        display: flex;
    }
    input{
        width: 95%;
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    select{
        border-radius: 5px;
        border: 1px solid lightgrey;
        width: 98%;
        &.selected {
            border: 2px solid lightgrey; // Cambia el borde a rojo después de la selección
          }
        &:focus {
            border: 2px solid lightgrey; 
          }
    }
  }