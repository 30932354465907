.contButton{
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
}
.ContgenButton{
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 16%;
}