



.HeaderStyle{
    width: 100%;
    height: 100%;
    background: var(--head-backgroud-color);
    display:flex;
    align-items: center;
}
.LogoContainer{
    width: 10%;
    height: 100%;
    position: relative;
}
.SearchContainer{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.ButtonsContainer{
    width: 20%;
    height: 15%;
    position: absolute;
    display: flex;
    right: 0;
}
.ButtonContainer{
    width: 25%;
    height: 100%;
    z-index: 1;
}