.logostyle{
    width: 50px;
    height: 55px;
    position: relative;
    img{
        position: absolute;
        object-fit: scale-down;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}
