.textosmenuizq{
    color: var(--color_text-primary);
    text-align: left;
    font-family: var(--text_font-family1);
    font-size: small;
    width: 100%;
   
    cursor: pointer;
    p{
        margin: 0;
    }
   /* @media (max-width: 852px) {
        font-size: x-small;
    }
    @media (min-width: 1281px){
        font-size: x-large;
    }*/
}
.textosmenuizq p:hover{
    background:#cce3f8;
    box-shadow: 1px 5px 5px grey;
    border-radius: 10px;
}
.estiloFIEL{
    color: var(--color_text-secundary);
    font-weight: bold;
}