.contForm{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.contForm label{
    padding-bottom: 8px;
    height: 25%;
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    position:relative;
}
.contForm label input{
    border: none;
    border-bottom: 3px var(--head-backgroud-color) solid;
    width: 99%;
    margin: 0 auto;
}
input:focus{
    outline: none;
}
::-ms-reveal {
    display: none;
}
::placeholder{
    color: #BDC3C7; } ;

::-webkit-input-placeholder { color:#BDC3C7; } 
:-moz-placeholder { /* Firefox 18- */ color:#BDC3C7; } 
::-moz-placeholder { /* Firefox 19+ */ color:#BDC3C7; } 
:-ms-input-placeholder { color:#BDC3C7; }
.stylesIcon{
    position: absolute;
    display: block;
    right: 0rem;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background: transparent;
    border: none;
    color: var(--color_text-secundary);
    font-size: x-large;
}
.contLogout{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 4%;

}
.contLogout p{
    font-size: var(--text_paragraph);
    color: black;
    cursor: pointer;
    margin-top: 5px;
}
.contsocialbutton{
    width: 100%;
    height: 45px;
    display: flex;
    padding-top: 16%;
    padding-bottom: 32%;
    justify-content: space-between;
}
.contsocialbutton button{
    border-radius: 10px;
    border: 2px solid #EAEDED;
    background: transparent;
    width: 40%;
    margin: 0 auto;
    box-shadow: 0 5px 2px -2px rgba(229, 222, 222, 0.9),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
}
.contbutton{
    display: flex;
    align-items: center;
}
.namesocial{
    width: 70%;
    margin: 0 auto;
    font-size: 14px;
    @media (max-width: 560px) {
        font-size: 11px;
    }
}
.bordercolor{
    border: var(--head-backgroud-color);
}
