.logo{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo img{
    width: 60%;
    height: 60%;
    position: absolute;
    object-fit: contain;
}