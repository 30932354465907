.contButton{
    /*width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;*/
    height: 33%;
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
}
.ContgenButton{
    width: 100%;
    /*position: absolute;*/
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /*padding-top: 16%;*/
}
Button{
    background: none;
    border: none;
}

.styleText{
    /*padding-top: 30%;
    padding-bottom: 20%;*/
    height: 35%;
    display: flex;
    align-items: center;
}
.section1{
    height: 10%;
    display: flex;
    align-items: center;
}
.section2{
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section3{
    height: 35%;
    display: flex;
    align-items: center;
}