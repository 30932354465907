.contenedorLogo{
    width: 10%;
    height: 100%;
    position: relative;
}
.logo{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
