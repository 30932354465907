/*CONTENEDORES*/

.HeaderStyle{
    width: 50%;
    height: 100%;
    background: var(--head-backgroud-color);
    display:flex;
    align-items: center;
    margin: 0 auto;
}
.LogoContainer{
    width: 100%;
    height: 100%;
    position: relative;
}
.contenedorBuscador{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.contenedorBotonera{
    width: 20%;
    height: 15%;
    position: absolute;
    display: flex;
    right: 0;
}
.contboton{
    width: 25%;
    height: 100%;
    z-index: 1;
}