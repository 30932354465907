.up{
    width: 100%;
    height: 30%;
    display: flex;
}
.down{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
}
.logo{
    width: 45%;
    height: 100%;
}
.names{
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.companyname{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
}
.username{
    width: 100%;
}
.title{
    width: 100%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}