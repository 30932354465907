.titleverifyemail{
    text-align:center;
    padding-top: 8%;
    padding-bottom: 8%;
    font-size: var(--text_size_subtitle);
    color: black;
    font-family: var(--text_font-family2);
    justify-content: center;
    display: flex;
    font-weight: bold;
}