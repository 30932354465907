.contButton{
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
}
.ContgenButton{
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}
.contText{
    width: 100%;
    height: 55%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: Center;
    padding-top: 12%;
    padding-bottom: 20%;
}