
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="email"]::placeholder  {
  text-align: left;
}
/*ESTILOS DE LA PLNATILLA*/
.overallContainer{
    width: 90%;
    margin: 0 auto;
    padding: 0;
    height: 100vh;
    /*min-width: 660px;*/
    /*border: 1px solid grey;*/
    position: relative;
  }
  .headerContainer{
    width: 100%;
    height: 15%;
    /*border: 1px solid purple;*/
    display: flex;
    align-items: center;
  }
  .center{
    width: 50%;
    display: flex;
    align-items: center;
    height: 100%;
    /*border: 1px solid blue;*/
    box-shadow: -1px 5px 15px #888888;
  }
  .centerContainer{
    width: 100%;
    height: 85%;
    /*border: 1px solid pink;*/
    display: flex;
  }
  .leftContainer{
    width: 25%;
    height: 100%;
    /*border: 1px solid green;*/
  }
  .rightContainer{
    width: 25%;
    height: 100%;
    /*border: 1px solid red;*/
  }
  .contLogin{
    width: 300px;   /*estaba en 50%*/
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
  }
  .contCenter{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
  }
  .contWelcome{
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

/*ESTILO DE LOS BOTONES BLANCOS Y MORADOS*/
.styleButtonPurple{
  border-radius: 10px;
  height: 35px;
  width: 95%;
  margin: 0 auto;
  border: none;
  background: var(--head-backgroud-color);
  color: var(--color_text-tertiary);
  font-size: var(--text_paragraph);
  margin-top: 8%;
  box-shadow: 0 5px 2px -2px rgba(121, 30, 143, 0.9),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.styleButtonWhite{
  border-radius: 10px;
  height: 35px;
  width: 95%;
  margin: 0 auto;
  border: none;
  background: var(--background-color);
  color: var(--head-backgroud-color);
  font-size: var(--text_paragraph);
  margin-top: 8%;
  box-shadow: 0 5px 2px -2px rgba(229, 222, 222, 0.9),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  flex-direction: column;
}
.text-spinner{
  margin-top: 40px;
  text-align: center;
}
